<!--
 * @Description: 新增、修改城市
 * @Author: 琢磨先生
 * @Date: 2022-05-10 15:39:28
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 14:37:21
-->

<template>
  <el-dialog
    v-model="drawer"
    :title="title"
    width="800px"
    top="10vh" 
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="父级">
        <el-cascader
          v-model="form.fatherIds"
          :options="area_list"
          :props="props"
          @change="handleChange"
        />
        <div class="help-block">不选择为最顶级，通常为省份</div>
      </el-form-item>

      <el-form-item label="名称" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder="请输入城市名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="首字母" prop="first_spell">
        <el-col :span="6">
          <el-input
            v-model="form.first_spell"
            placeholder="请输入首字母"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="行政区域编码" prop="adcode">
        <el-col :span="6">
          <el-input
            v-model="form.adcode"
            placeholder="请输入行政区域编码"
          ></el-input>
        </el-col>
        <div class="help-block">请输入六位行政划区编码，例如三亚为460200</div>
      </el-form-item>
      <el-form-item label="中心位置" prop="lng">
        <el-button type="primary" link size="small" @click="openMap">
          <el-icon><add-location /></el-icon>
          坐标拾取</el-button
        >{{ form.lng ? form.lng + "," + form.lat : "" }}
        <div class="help-block">
          市区请务必设置中文位置坐标，通常以市区政府为中心
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio
            :label="item.value"
            v-for="item in statusList"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="可搜索">
        <el-switch v-model="form.is_search"></el-switch>
        <div class="help-block">可在城市选择列表选择，通常为城市</div>
      </el-form-item>
      <el-form-item label="默认">
        <el-switch v-model="form.is_default"></el-switch>
        <div class="help-block">默认城市列表，只存在一个</div>
      </el-form-item>
      <el-form-item label="序号" prop="sequence">
        <el-col :span="6">
          <el-input v-model="form.sequence" placeholder="正序排序"></el-input>
        </el-col>
        <div class="help-block">正序排序，数值越小越靠前</div>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="loading"
          :disabled="loading"
          >确定</el-button
        >
        <!-- <el-button @click="cancelClick" :disabled="loading">取消</el-button> -->
      </el-form-item>
    </el-form>
  </el-dialog>
  <map-picker
    :area="area"
    :lng="form.lng"
    :lat="form.lat"
    :change="pickerChange"
  ></map-picker>
</template>

<script>
import MapPicker from "@/views/map/map_picker";
import common_api from "@/http/common_api";
import area_api from "@/http/area_api";
export default {
  components: {
    MapPicker,
  },
  data() {
    return {
      loading: false,
      drawer: false,
      title: "编辑城市区域",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入城市区域名称",
            trigger: "blur",
          },
        ],
        first_spell: [
          {
            required: true,
            message: "请输入名称首字母",
            trigger: "blur",
          },
        ],
        adcode: [
          {
            required: true,
            message: "请输入行政区域编码",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      props: {
        label: "name",
        value: "id",
        expandTrigger: "hover",
        checkStrictly: true,
      },
      //地图位置拾取使用
      area: null,
      //状态
      statusList: [],
      area_list: [],
    };
  },
  props: ["item", "list", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
          area_api.get_areas().then((res) => {
            if (res.code == 0) {
              this.area_list = res.data;
            }
          });
        }
      },
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.statusList = res.data.area_status;
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/admin/v1/area/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 选择的父级
     */
    handleChange(values) {
      this.form.fatherIds = values;
    },
    /**
     * 打开定位
     */
    openMap() {
      // var area = this.areas.find((x) => x.id == this.form.area_id);
      this.area = {};
    },
    /**
     * 坐标选取更改
     */
    pickerChange(val) {
      this.area = null;
      if (val) {
        this.form.lat = val.lat;
        this.form.lng = val.lng;
      }
    },
  },
};
</script>